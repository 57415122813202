<template>
  <div class="viewContainer csv">
    <h1>Bestand updaten</h1>
    <StepNavigation :step="currentStep" :stepTitles="stepTitles" />
    <div class="step0">
      <b-row v-if="currentStep === 0">
        <b-col>
          <h2 class="v2">Datei auswählen</h2>
          <input ref="fileInput" type="file" @change="readFile" class="d-none" />
          <button class="btn btn-primary" @click="$refs.fileInput.click()">Datei auswählen</button>
          <b-row v-if="fileName.length > 0 && csvColumns.length > 0">
            <b-alert class="updater" fade variant="success" :show=showAlert>
              Datei "{{ fileName }}" erfolgreich hochgeladen, {{ csvInput.length }} Zeilen gefunden.
            </b-alert>
          </b-row>
          <ButtonFooter :backButtonVisible="false" :okButtonDisabled="fileName.length === 0 || csvColumns.length === 0"
            :okButtonLabel="this.$t('general.buttons.forward')" :okAction="() => { currentStep += 1 }" />
        </b-col>
      </b-row>
    </div>
    <div class="step1">

      <b-row v-if="currentStep === 1">
        <b-col>
          <h2 class="v2">{{ this.$t('updater.step1') }}</h2>
          <TargetMapping :csvColumns="csvColumns" :wineSupplierNotFound="wineSupplierNotFound"
            :finishedMapping="finishedTargetMapping" :rowMappingObjectStart="rowMappingObject"
            @backFromStep1="handleBackFromStep1" />

        </b-col>
      </b-row>
    </div>
    <div v-if="csvColumns.length > 0">
      <div class="step2">


        <IdentifierMapping v-if="currentStep === 2" :csvColumns="csvColumns"
          :finishedMapping="finishedIdentifierMapping" :identifierMappingObject="identifierMappingObject"
          :preSelectedOption="selectedIdentifierOption" @backFromStep2="handleBackFromStep2" />
      </div>

      <b-row class="mt-3" v-if="currentStep === 3">
        <b-col>
          <div v-if="isImporting">
            <h2>Die Liste wird überprüft...</h2>
          </div>
          <div v-else>
            <h2>Überprüfung der Liste abgeschlossen.</h2>
            <div class=" mt-4 mb-4">
              <b-alert variant="warning" :show="true" class="updater"> {{ notFoundWines.length }} Weine wurden nicht
                gefunden</b-alert>
              <b-alert :show="true" class="updater"> {{ foundWinesCount }} Weine wurden gefunden</b-alert>
            </div>
            <b-row v-if="matchingWines.length > 0" class="">
              <b-col>
                <h4>
                  Gefundene Produkte
                </h4>
                <b-row>
                  <b-col>
                    <h3>CSV Wein</h3>
                  </b-col><b-col cols="1"> </b-col><b-col>
                    <h3>Produkt aus Datenbank</h3>
                  </b-col><b-col cols="1">
                    <h3>Glasweise</h3>
                  </b-col><b-col cols="1">
                    <h3>Übernehmen</h3>
                  </b-col>
                </b-row>
                <b-row v-for="(wine, index) in matchingWines" :key="index">
                  <b-col>{{ wine.vineFromSheet }}</b-col>
                  <b-col cols="1"> ------- > </b-col>
                  <b-col>{{ wine.vineFromVenue }}</b-col>
                  <b-col cols="1"><button
                      :class="glasWines.indexOf(wine.row) < 0 ? 'inlineRow remove' : 'inlineRow accept'"
                      @click="addOrRemoveRowFromGlaswines(wine.row, wine.csvWine)"></button></b-col>
                  <b-col cols="1"><button v-if="wine.fromSearch"
                      :class="excludeRowsFromUpdate.indexOf(wine.row, wine.csvWine) > -1 ? 'inlineRow cancel' : 'inlineRow accept'"
                      @click="addOrRemoveRowFromExludes(wine.row, wine.csvWine)"></button></b-col>
                </b-row>
              </b-col>
            </b-row>

            <b-row v-if="notFoundWines.length > 0" class="mt-2">
              <b-col>
                <h4>
                  Nicht gefundene Produkte
                </h4>
              </b-col>
            </b-row>

            <b-row v-if="notFoundWines.length > 0" class="mt-2 stripedTable">
              <b-col>
                <b-table sticky-header="250px" :items="notFoundWines" :fields="notFoundListColumnsToShow">
                </b-table>
                <b-button size="sm" class="mb-3" @click="exportCSV()">Als CSV exportieren:</b-button>

              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <h4>Datum der Aktualisierung setzen:</h4>
                <b-form-datepicker class="mt-3 mb-3" :locale="$i18n.locale" id="fromDatePicker"
                  v-model="importDate"></b-form-datepicker>
                <ButtonFooter class="mt-3 mb-3" :backButtonVisible="true" :okButtonDisabled="false"
                  :backButtonLabel="this.$t('general.buttons.back')" :okButtonLabel="this.$t('updater.updateWines')"
                  :okAction="() => { startRealUpdate() }" :backAction="handleBackFromStep3" />
              </b-col>
            </b-row>
          </div>
        </b-col>

      </b-row>
      <b-row class="mt-3" v-if="currentStep === 4">
        <b-col>
          <h2>Update läuft</h2>
          <p>{{ infoText }}</p>
          aktuelle Zeile: {{ currentRow }} / {{ csvInput.length }}
        </b-col>
      </b-row>
      <b-row class="mt-3 stripedTable" v-if="currentStep === 5">
        <b-col>
          <b-alert variant="success" :show="true" class="updater">Update erfolgreich abgeschlossen</b-alert>
          <b-table sticky-header="500px" :items="finishedWines" :fields="finishedColumnsToShow" style="height: ">
          </b-table>
        </b-col>
      </b-row>
      
    </div>

    <b-row v-if="csvError.length > 0">
      <b-col>

        <h4 class="alert-heading">Fehler beim Einlesen der CSV Datei</h4>
        <p>
          {{ csvError }}
        </p>

      </b-col>
    </b-row>


  </div>
</template>
<script>
import DataService from "@/services/StrapiService.js";
import TargetMapping from "@/components/updater/TargetMapping.vue";
import IdentifierMapping from "@/components/updater/IdentifierMapping.vue";
import { parsePotentiallyGroupedFloat } from "@/utils/functions.js";
import { addSupplierAndPrice, matchVenueWineWithEveryColumn, matchValues, deepValueOfObject, generateWineNameFromColumns, generateWineString, addNestedStrapiAttributesToObjects } from "@/utils/venueWineFunctions.js";
import { read, utils } from 'xlsx';
import StepNavigation from "@/components/modals/addWine/StepNavigation.vue";
import ButtonFooter from "@/components/modals/ButtonFooter.vue";

export default {
  name: "UpdateAmounts",
  components: {
    TargetMapping,
    IdentifierMapping,
    StepNavigation,
    ButtonFooter
  },
  data() {
    return {
      currentStep: 0,
      showAlert: false,
      stepTitles: [this.$t('updater.step0'), this.$t('updater.step1'), this.$t('updater.step2'), this.$t('updater.step3')],
      wineSupplierNotFound: [],
      csvColumns: [],
      offlineWineSupplierList: [],
      csvInput: [],
      csvError: [],
      rowMappingObject: {},
      deleteWine: false,
      useAsDifference: false,
      addToProduct: false,
      identifierMappingObject: {},
      identifierToVenueWineKey: {
        'Artikelnummer': 'attributes.articleNumber',
        'Weingut': 'attributes.wine.data.attributes.winery.data.attributes.title',
        'Weinname': 'attributes.title',
        'Jahrgang': 'attributes.year',
        'Region': 'attributes.wine.data.attributes.region.data.attributes.title',
        'Land': 'attributes.wine.data.attributes.land.data.attributes.title',
        'Farbe': 'attributes.wine.data.attributes.wineType.data.attributes.title',
        'Rebsorte': 'attributes.wine.data.attributes.grapeVariety.data.attributes.title',
        'Flaschengröße': 'attributes.bottleSize.data.attributes.amount',
        'VK': 'attributes.sellingPrice',
        'Kommentar': 'attributes.comments',
      },
      indentifierToSearch: {
        'Artikelnummer': 'articleNumber',
        'Weingut': 'winery',
        'Weinname': 'title',
        'Jahrgang': 'year',
        'Region': 'region',
        'Land': 'country',
        'Farbe': 'wineType',
        'Rebsorte': 'grapeVariety',
        'Flaschengröße': 'bottleSize',
        'VK': 'sellingPrice',
        'Kommentar': 'comments',
      },
      finishedColumnsToShow: [
        'Produkt',
        'Aenderung',
        'Vorher Flasche',
        'Vorher Glas',
        'Nachher Flasche',
        'Nachher Glas'
      ],
      finishedWines: [],
      fileName: '',
      selectedIdentifierOption: 'Standard',
      currentRow: 0,
      foundWinesCount: 0,
      matchingWines: [],
      notFoundWines: [],
      notFoundListColumnsToShow: [
        'Produkt',
        'Grund'
      ],
      isImporting: false,
      importDate: new Date(Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate())),
      dateDidChange: false,
      excludeRowsFromUpdate: [],
      glasWines: [],
      infoText: '',
      offlineVenueWine: [],
    }
  },
  created() {
  },
  methods: {
    handleBackFromStep1() {
      this.currentStep = this.currentStep - 1;
    },
    handleBackFromStep2() {
      this.currentStep = this.currentStep - 1;
    },
    handleBackFromStep3() {
      this.currentStep = this.currentStep - 1;
    },
    async readFile(event) {
      const file = event.target.files[0];
      this.fileName = file.name;

      if (file.name.endsWith('.xlsx')) {
        await this.readExcel(event, file);
        this.showAlert = true;
      } else if (file.name.endsWith('.csv')) {
        this.readCSV(event, file);
        this.showAlert = true;
      } else {
        console.log('Die Datei ist keine .xlsx oder .csv Datei');
        return;
      }
    },
    async readExcel(event, file) {
      const ab = await file.arrayBuffer();
      const wb = read(ab);
      const xlsxRows = utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]);
      const xlsxColumns = Object.keys(xlsxRows[0]);
      this.csvColumns = xlsxColumns
      this.csvInput = xlsxRows;
    },
    readCSV(event, file) {
      const self = this;
      var csvinput = [];
      var csverror = [];
      var count = 0;
      // this.offlineWineSupplier.map((field) => {if (this.wineSupplierNotFound.indexOf(field.attributes.title) == -1) { this.wineSupplierNotFound.push(field.attributes.title)}}) 
      this.$papa.parse(file, {
        //worker: true, // Don't bog down the main thread if its a big file
        header: true,
        download: true,
        skipEmptyLines: true,
        transformHeader: function (h) {
          return h.trim();
        },
        step: function (result) {
          // do stuff with result
          count = count + 1;
          if (result.errors.length == 0) {
            // clean up data
            if (result.data['Bezugsquelle'] != null && self.offlineWineSupplierList.indexOf(result.data['Bezugsquelle']) == -1 && self.wineSupplierNotFound.indexOf(result.data['Bezugsquelle']) == -1) {
              self.wineSupplierNotFound.push(result.data['Bezugsquelle']);
            }

            if (result.data['Flaschengröße'] != null && Number(result.data['Flaschengröße']) === 375 || result.data['Flaschengröße'] === '375') {
              result.data['Flaschengröße'] = 0.375;
            }
            if (result.data['EK'] != null) {
              result.data['EK'] = parsePotentiallyGroupedFloat(result.data['EK']);
            }
            if (result.data['VK'] != null) {
              result.data['VK'] = parsePotentiallyGroupedFloat(result.data['VK']);
            }
            csvinput.push(result.data);
          } else {
            csverror.push(result.errors[0]);
          }
        },
        complete: function () {
          self.csvError = csverror;
          self.csvInput = csvinput;
          self.csvColumns = Object.keys(csvinput[0]);
        },
      });
    },
    finishedTargetMapping(rowMappingObject, useAsDifference, addToProduct, deleteWine) {
      this.rowMappingObject = rowMappingObject;
      this.currentStep = this.currentStep + 1;
      this.useAsDifference = useAsDifference;
      this.addToProduct = addToProduct;
      this.deleteWine = deleteWine;
    },
    finishedIdentifierMapping(identifierMappingObject, selectedIdentifierOption) {
      this.identifierMappingObject = identifierMappingObject;
      this.selectedIdentifierOption = selectedIdentifierOption;
      this.updateVenueWines(true);
      this.currentStep = this.currentStep + 1;
    },
    findVenueWineFunction(offlineVenueWine, totalStringArray, identifierKeys, matchingIdentifier) {
      var isMatching = true;
      // Kommentare, articleNummer
      identifierKeys.forEach((identifierKey) => {
        var venueWineIdentifier = this.identifierToVenueWineKey[identifierKey];
        // ...articleNumber
        var valueA = deepValueOfObject(offlineVenueWine, venueWineIdentifier);

        // if we have no more values to compare
        if (totalStringArray.length == 0)
        {
          isMatching = true;
          return;
        }
        // if the value is null we can't compare
        if (valueA == null) {
          isMatching = false;
          return;
        }
        var identfierDidMatch = false;
      
        for (var i = 0; i < totalStringArray.length; i++) {
          var valueB = totalStringArray[0];
          if (totalStringArray.length > 1 && i > 0) {
            const slicedArray = totalStringArray.slice(0, i+1);
            valueB = slicedArray.join(' ');
          }
          // add more parts of the string to the comparison
          if (matchValues(valueA, valueB)) {
            identfierDidMatch = true;
            if (i == 0) {
              totalStringArray = totalStringArray.slice(1);
            } else {
              totalStringArray = totalStringArray.slice(i+1);
            }
            this.removeIdentifierFromArray(identifierKey, matchingIdentifier)
            break
          }
        }

        isMatching = identfierDidMatch;
      })
      return isMatching;
    },
    async updateVenueWines(onlyTemporarily) {
      this.isImporting = true
      this.currentRow = 0;
      if (this.offlineVenueWine.length == 0) {
        this.infoText = 'Lade Weine aus der Datenbank';
        const venueWineResponse = await DataService.getVenueWines();
        this.offlineVenueWine = venueWineResponse["data"].data;
      }
  
      if (onlyTemporarily) {
        this.notFoundWines = [];
        this.foundWinesCount = 0;
        this.matchingWines = [];
      }


    
      var identifierKeys = Object.keys(this.identifierMappingObject);
      console.log(identifierKeys, 'identifierKeys')
      var duplicateColumns = this.findDuplicateValuesInObject(this.identifierMappingObject);

      // we map all identfiers with one column
      var duplicateColumn = duplicateColumns[0];
      this.infoText = 'Aktualisiere Weine';
      for await (const csvWine of this.csvInput) {
        var venueWine = null;
        this.currentRow = this.currentRow + 1;
        var matchingIdentifier = JSON.parse(JSON.stringify(identifierKeys));

        if (duplicateColumns.length > 0) {

          var totalString = csvWine[duplicateColumn];
          var totalStringArray = totalString.split(' ');

          venueWine = this.offlineVenueWine.find((offlineVenueWine) => {
            return this.findVenueWineFunction(offlineVenueWine, totalStringArray, identifierKeys, matchingIdentifier)
            }
          );
        } else {

          venueWine = this.offlineVenueWine.find((offlineVenueWine) => {
            var isMatching = matchVenueWineWithEveryColumn(identifierKeys, offlineVenueWine, csvWine, this.identifierToVenueWineKey, this.identifierMappingObject, (identifierKey) => { this.removeIdentifierFromArray(identifierKey, matchingIdentifier) });
            return isMatching;
          });

        }
        var vineFromSheet = generateWineNameFromColumns(csvWine, this.identifierMappingObject);
        csvWine['Produkt'] = vineFromSheet;
        if (this.couldBeGlassWine(csvWine) && onlyTemporarily) {
          this.addOrRemoveRowFromGlaswines(this.currentRow);
        }
        var withSearch = ''
        if (!venueWine) {
          var strapiIdentiferKeys = identifierKeys.map((key) => {
            return this.indentifierToSearch[key];
          });
          var searchResult = await DataService.searchVenueWines(vineFromSheet, strapiIdentiferKeys);
          if (searchResult.data && searchResult.data.length > 0) {
            var venueWineFromSearch = searchResult.data[0];

            if (venueWineFromSearch._score > 10) {
              venueWine = venueWineFromSearch;
              venueWine = addNestedStrapiAttributesToObjects(venueWine).data;
              withSearch = '* (' + venueWineFromSearch._score + ')';
            }
          }
        }
        if (venueWine) {
          if (!onlyTemporarily && this.excludeRowsFromUpdate.indexOf(this.currentRow) == -1) {
          
            var venueWineObject = this.constructVenueWineObjectFromSelectedFields(csvWine, venueWine, this.glasWines.indexOf(this.currentRow) > -1);
            this.finishedWines.push({
              Produkt: generateWineString(venueWine),
              'Aenderung': venueWineObject.aenderung ? venueWineObject.aenderung.trim() : '',
              'Vorher Flasche': venueWine.attributes.amountFloat,
              'Vorher Glas': venueWine.attributes.amountGlasses,
              'Nachher Flasche': venueWineObject.attributes.amountFloat,
              'Nachher Glas': venueWineObject.attributes.amountGlasses
            });

            delete venueWineObject.aenderung;
            await DataService.updateVenueWine(venueWine.id, venueWineObject);
          } else {
            this.foundWinesCount = this.foundWinesCount + 1;
            if (duplicateColumns.length > 0) {
              csvWine['Weinname'] = totalString;
            }
            this.matchingWines.push({
              vineFromSheet: vineFromSheet,
              vineFromVenue: generateWineString(venueWine) + ' ' + withSearch,
              row: this.currentRow,
              fromSearch: withSearch !== '',
              csvWine: csvWine
            });
          }
        } else {
          if (!onlyTemporarily) {
            continue;
          }
          csvWine['Grund'] = matchingIdentifier ? matchingIdentifier[0] : 'Kein Grund gefunden';
          csvWine['Zeile'] = this.currentRow + 1;
          if (duplicateColumns.length > 0) {
            csvWine['Weinname'] = totalString;
          }
          this.notFoundWines.push(csvWine)
        }
      }

      if (onlyTemporarily) {
        this.currentStep = 3;
      } else {
        this.currentStep = 5;
      }
      this.isImporting = false
    },
    removeIdentifierFromArray(word, array) {
      var index = array.indexOf(word);
      if (index > -1) {
        array.splice(index, 1);
      }
    },
    findDuplicateValuesInObject(obj) {
      var seen = {};
      var dupes = [];
      var keys = Object.keys(obj);
      keys.forEach(element => {
        var value = obj[element];
        if (seen[value]) {
          dupes.push(value);
        } else {
          seen[value] = true;
        }
      });
      return dupes;
    },
    constructVenueWineObjectFromSelectedFields(csvRow, storedVenueWine, isGlassWine) {
      console.log(csvRow, storedVenueWine, isGlassWine)
      var venueWineAttributesMappingObject = {
        'Anzahl': 'amountFloat',
        'VK': 'sellingPrice',
        'Regal': 'shelfNumber',
        'Artikelnummer': 'articleNumber',
        'Kommentar': 'comments',
        'Kassennotiz': 'cashNote',
        'Bezugsquelle': 'purchasePrices',
      };
      var venueWineObject = {
        attributes:
        {
          noRevenue: true,
          causeOfReduction: 'inventory'
        }
      };
      if (this.dateDidChange) {
        venueWineObject.attributes.newCreateDate = this.importDate;
      }
      if (this.deleteWine) {
        venueWineObject.attributes.deleted = true;
        return venueWineObject;
      } 
      venueWineObject['aenderung'] = ''
      var selectedFieldsToChange = Object.keys(this.rowMappingObject);
      selectedFieldsToChange.forEach((field) => {
        var venueWineAtrribute = venueWineAttributesMappingObject[field];
        if (venueWineAtrribute === 'amountFloat' && isGlassWine) {
          venueWineAtrribute = 'amountGlasses'
        }

        var value = csvRow[this.rowMappingObject[field]];
        venueWineObject['aenderung'] += ' '+value;
        console.log(this.rowMappingObject[field], csvRow)
        if (venueWineAtrribute == 'purchasePrices') {

          const wineSupplier = this.offlineWineSupplier.find((wineSupplier) => {
            return wineSupplier.attributes.title.toLowerCase() == csvRow[field].toLowerCase();
          });
          if (!wineSupplier) {
            csvRow['Grund'] = 'Keinen vorhandenden Lieferant gefunden';
            this.notFoundWines.push(csvRow)
            return;
          }
          var venueWine = addSupplierAndPrice(storedVenueWine, csvRow['EK'], wineSupplier)
          venueWineObject['attributes'][venueWineAtrribute] = venueWine['attributes'][venueWineAtrribute]
        } else {

          if ((this.useAsDifference || this.addToProduct) && !isNaN(Number(value)) && !isNaN(Number(storedVenueWine['attributes'][venueWineAtrribute]))) {
            if (isGlassWine){
              if (venueWineAtrribute == 'amountGlasses') {
                console.log('amountGlasses')
                var bottles = 0
                var glasses = 0
                if (this.useAsDifference) {
                  if (value > storedVenueWine['attributes']['amountGlasses']) {
                    
                    // calc amount of bottles to reduce and amount of glasses to reduce
                    var glassesbefore = Number(storedVenueWine['attributes']['amountGlasses']);
                    var glassesPerBottle = 7;

                    bottles = Math.floor(value / glassesPerBottle);
                    glasses = value % glassesPerBottle;

                    if (glassesbefore < glasses) {
                      bottles = bottles + 1;
                      glasses = glasses - glassesPerBottle;
                    }

                    venueWineObject['attributes']['amountFloat'] = Number(storedVenueWine['attributes']['amountFloat']) - bottles;
                    venueWineObject['attributes']['amountGlasses'] = glassesbefore - glasses;
                  } else {
                    venueWineObject['attributes']['amountGlasses'] = Number(storedVenueWine['attributes']['amountGlasses']) - Number(value);
                    venueWineObject['attributes']['amountFloat'] = Number(storedVenueWine['attributes']['amountFloat']);
                  }
                } else {
                  if (value + storedVenueWine['attributes']['amountGlasses'] > 7) {
                    bottles = Math.floor((value + storedVenueWine['attributes']['amountGlasses']) / 7);
                    glasses = (value + storedVenueWine['attributes']['amountGlasses']) % 7;
                    venueWineObject['attributes']['amountFloat'] = Number(storedVenueWine['attributes']['amountFloat']) + bottles;
                    venueWineObject['attributes']['amountGlasses'] = glasses;
                  } else {
                    venueWineObject['attributes']['amountGlasses'] = Number(storedVenueWine['attributes']['amountGlasses']) + Number(value);
                    venueWineObject['attributes']['amountFloat'] = Number(storedVenueWine['attributes']['amountFloat']);
                  }
                 
                }
              } else {
                venueWineObject['attributes'][venueWineAtrribute] = Number(storedVenueWine['attributes'][venueWineAtrribute]) + Number(value);
               
              }
            } else {
              if (this.addToProduct) {
                venueWineObject['attributes'][venueWineAtrribute] = Number(storedVenueWine['attributes'][venueWineAtrribute]) + Number(value);
              } else {
                venueWineObject['attributes'][venueWineAtrribute] = Number(storedVenueWine['attributes'][venueWineAtrribute]) - Number(value);
              }
            }
            //TODO add more Logic - Reduce bottle amount...
          } else {
            venueWineObject['attributes'][venueWineAtrribute] = value;
          }

        }

      });
      return venueWineObject;
    },
    exportCSV() {
      var csv = this.$papa.unparse(this.notFoundWines);
      var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      var link = document.createElement("a");
      var url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "notFoundWines.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    startRealUpdate() {
      this.updateVenueWines(false);
      this.currentStep = 4;
    },
    addOrRemoveRowFromExludes(row, wineFromSheet) {
      var index = this.excludeRowsFromUpdate.indexOf(row);
      if (index > -1) {
        // TODO remove from notFoundWines
        this.excludeRowsFromUpdate.splice(index, 1);
        this.notFoundWines = this.notFoundWines.filter((wine) => {
          return wine['Produkt'] !== wineFromSheet['Produkt'] && wine['Grund'] !== 'Manuell ausgeschlossen';
        });
      } else {
        wineFromSheet['Grund'] = 'Manuell ausgeschlossen';
        this.notFoundWines.push(wineFromSheet)
        this.excludeRowsFromUpdate.push(row);
      }
    },
    addOrRemoveRowFromGlaswines(row) {
      var index = this.glasWines.indexOf(row);
      if (index > -1) {
        this.glasWines.splice(index, 1);
      } else {
        this.glasWines.push(row);
      }
    },
    couldBeGlassWine(wineFromSheet) {
      return wineFromSheet.Produkt.includes('0,1') || wineFromSheet.Produkt.includes('0,2') || wineFromSheet['Flaschengröße'] == 0.1 || wineFromSheet['Flaschengröße'] == 0.2;
    }
  },
  watch: {
    importDate: function () {
      this.dateDidChange = true;
    }
  },
};
</script>
<style scoped>

</style>